/**
 * BEM utility class,
 * Creates bem classes for you
 */

export interface BemDefaults {
  prefix?: string;
  modifierDelimiter?: string;
  elementDelimiter?: string;
}

export class Bem {
  private block: string;
  private defaults: BemDefaults;

  constructor(block: string, defaults?: BemDefaults) {
    this.block = block;
    this.defaults = defaults || {
      elementDelimiter: "__",
      modifierDelimiter: "--",
      prefix: "",
    };
  }

  public with(element?: string, modifiers?: any): string {
    let baseString = "";
    if (!!element) {
      baseString = `${this.block}${this.defaults.elementDelimiter}${element}`;
    } else {
      baseString = `${this.block}`;
    }

    if (!!modifiers) {
      let modifierClasses = baseString;

      Object.keys(modifiers).forEach((key) => {
        if (modifiers[key] === true) {
          const newModifier = `${baseString}${this.defaults.modifierDelimiter}${key}`;
          modifierClasses = `${modifierClasses} ${newModifier}`;
        }
      });

      return modifierClasses;
    }

    return baseString;
  }

  public withModifierArray(element?: string, modifiers?: string[]): string {
    let baseString = "";
    if (!!element) {
      baseString = `${this.block}${this.defaults.elementDelimiter}${element}`;
    } else {
      baseString = `${this.block}`;
    }

    if (!!modifiers) {
      let modifierClasses = baseString;

      modifiers.forEach((key) => {
        const newModifier = `${baseString}${this.defaults.modifierDelimiter}${key}`;
        modifierClasses = `${modifierClasses} ${newModifier}`;
      });

      return modifierClasses;
    }

    return baseString;
  }
}
