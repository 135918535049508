import React from "react";
import { Bem } from "../../utils/bem";
import scrollTo from "../../utils/scrollTo";
import "./styles.scss";

interface Props {
  center?: boolean;
  title: string;
  showSocial?: boolean;
  strapline?: string;
  autoScroll?: boolean;
}

const component = new Bem("c-header");

const Header = ({ title, showSocial, strapline, center, autoScroll }: Props) => {
  const autoScrollTo = () => {
    const element = document.getElementById("section-0");
    scrollTo(element);
  };

  return (
    <header className={component.with("", ["align"])}>
      <div className={component.with("wrapper", { center })}>
        <h1 className={component.with("title")}>{title}</h1>
        {strapline && <p className={component.with("strapline")}>{strapline}</p>}
        {showSocial &&
          <ul className={component.with("social-links")}>
            <li>
              <a href="https://twitter.com/twiggyrj" target="_blank" rel="noopener noreferrer">Twitter</a>
            </li>
            <li>
              <a href="https://github.com/TwiggyRJ" target="_blank" rel="noopener noreferrer">GitHub</a>
            </li>
            <li>
              <a href="mailto:aaronfryer@live.com" target="_blank" rel="noopener noreferrer">Email</a>
            </li>
          </ul>
        }
        {autoScroll && <button className={component.with("next-section")} onClick={autoScrollTo}>»</button>}
      </div>
    </header>
  );
};

export default Header;
