import { Link } from "gatsby";
import React from "react";
import { AuthorJson, ImageSharp } from "../../graphql-types";
import { Bem } from "../../utils/bem";
import "./styles.scss";

interface Props {
  title: string;
  image?: {
    src: string;
    srcSet: string;
  };
  description: {
    excerpt: string;
    slug: string;
    timeToRead: number;
    updatedDate: string;
  };
  author?: {
    meta: AuthorJson,
    avatar: ImageSharp,
  };
}

const component = new Bem("c-card");
const metaComponent = new Bem("c-card-meta");

const Card = ({ author, description, image, title }: Props) => {
  return (
    <div className={component.with()}>
      {image &&
        <img style={{ width: "100%" }} src={image.src} srcSet={image.srcSet} />
      }
      <div className={component.with("description")}>
        <h3 className={component.with("title")}>{ title }</h3>
        <p className={component.with("excerpt")}>{ description.excerpt }</p>
        <Link to={description.slug}>Read more…</Link>
      </div>
      {author && <div className={component.with("footer")}>
        <div className={component.with("avatar-container")}>
          <img src={author.avatar.fixed.src} srcSet={author.avatar.fixed.srcSet} />
        </div>
        <div className={component.with("meta")}>
          <p className={metaComponent.with("author")}>{author.meta.id}</p>
          <p className={metaComponent.with("read-time")}>
            { description.updatedDate } - { description.timeToRead } min read
          </p>
        </div>
      </div>}
    </div>
  );
};

export default Card;
