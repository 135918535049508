import React from "react";
import { Bem } from "../../utils/bem";
import "./styles.scss";

interface Props {
  center?: boolean;
  title?: string;
  strapline?: string;
}

const component = new Bem("c-footer");
const contentWrapper = new Bem("c-footer-wrapper");

const Header = ({ title, strapline, center }: Props) => {
  return (
    <footer className={component.with()}>
      <div className={contentWrapper.with()}>
        <p>
          If you want to find out more or have any questions
          &nbsp;
          <a href="mailto:aaronfryer@live.co.uk">feel free to get in touch.</a>
        </p>
        <ul className={component.with("social-links")}>
          <li>
            <a href="https://twitter.com/twiggyrj" target="_blank" rel="noopener noreferrer">Twitter</a>
          </li>
          <li>
            <a href="https://github.com/TwiggyRJ" target="_blank" rel="noopener noreferrer">GitHub</a>
          </li>
          <li>
            <a href="mailto:aaronfryer@live.com" target="_blank" rel="noopener noreferrer">Email</a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Header;
